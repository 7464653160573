import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '../src/assets/css/global.css'
import store from './store/index.js'
import { getDicts } from "@/api/system/dict/data";
import DictTag from '@/assets/DictTag'
import Pagination from "@/components/Pagination";
import { download } from '@/utils/request'
import Cookies from "js-cookie";
import './assets/css/mobile.css'

import VueI18n from 'vue-i18n'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import viLocale from 'element-ui/lib/locale/lang/vi'
import koLocale from 'element-ui/lib/locale/lang/ko'
import ptLocale from 'element-ui/lib/locale/lang/pt'
import frLocale from 'element-ui/lib/locale/lang/fr'
import thLocale from 'element-ui/lib/locale/lang/th'
Vue.use(VueI18n)
Vue.use(ElementUI)
// Vue.config.lang = 'zh-cn'
Vue.locale('zh-cn', zhLocale)
Vue.locale('en', enLocale)
Vue.locale('vi', viLocale)
Vue.locale('ko', koLocale)
Vue.locale('pt', ptLocale)
Vue.locale('fr', frLocale)
Vue.locale('th', thLocale)

// 翻译
import '../permission'
import { filterCountry, td, tf, tfp,tfps } from './utils/bd.js'
Vue.prototype.td = td
Vue.prototype.tf = tf
Vue.prototype.tfp = tfp
Vue.prototype.tfps = tfps
Vue.prototype.filterCountry= filterCountry
Vue.prototype.getDicts = getDicts
Vue.prototype.download = download

//全局挂载
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree  } from "@/utils/bd.js";
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm

let th = Cookies.get("langId")
if(th == undefined||th=="undefined"||th=='1'){
	Vue.config.lang = 'zh-cn'
}
if(th == undefined||th=="undefined"||th=='2'){
	Vue.config.lang = 'en'
}
if(th == undefined||th=="undefined"||th=='3'){
	Vue.config.lang = 'vi'
}
if(th == undefined||th=="undefined"||th=='4'){
	Vue.config.lang = 'ko'
}
if(th == undefined||th=="undefined"||th=='5'){
	Vue.config.lang = 'pt'
}
if(th == undefined||th=="undefined"||th=='6'){
	Vue.config.lang = 'fr'
}
if(th == undefined||th=="undefined"||th=='7'){
	Vue.config.lang = 'th'
}

Vue.prototype.baseURL = Cookies.get("request");

Vue.config.productionTip = false

// Vue.use(ElementUI);

Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
  	Vue.prototype.$bus = this
  }
  
}).$mount('#app')
