const getters = {
 
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  userid: state => state.user.userid,
  lang:state=>state.lang.lang,
  langDetail:state=>state.lang.langDetail,
  captionDetail:state=>state.lang.captionDetail,
}
export default getters
