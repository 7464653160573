import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import getters from './getters'
import lang from './modules/translate'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    lang,
  },
  getters
})

export default store