import request from '@/utils/request'
import { getToken } from "@/utils/auth"

//登录方法
export function login(username, password, code, uuid, ati) {
  return request({
    url:'/auth/login',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: {username, password, code, uuid, ati}
  })
}

export function phonelogin(username, password, code, ati,phonenumber,prefix) {
  return request({
    url:'/auth/login',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post',
    data: {username, password, code, ati,phonenumber,prefix}
  })
}


//注册
export function register(data) {
  return request({
    url:'/auth/register',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/getInfo',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get'
  })
}
// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get',
    timeout: 20000
  })
}

// 手机获取验证码
export function getCodetext(query,country) {
  return request({
    url: '/auth/smsCode?phone=' + query + '&prefix=' + country,
    // headers: {"Authorization": "Bearer  " + getToken()},
    method: 'post',
	// params: query,
    timeout: 20000
  })
}

// 重置密码
export function getRePassword(query) {
  return request({
    url: '/system/user/profile/updatePwdByPhone',
    // headers: {"Authorization": "Bearer  " + getToken()},
    method: 'post',
	data: query,
    timeout: 20000
  })
}

export function logout() {
  return request({
    url: '/auth/logout',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'delete'
  })
}

export function refreshToken() {
  return request({
    url: '/auth/refresh',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'post'
  })
}