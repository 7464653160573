import request from '@/utils/request'


// 查询语言列表
// export function getLang() {
//   return request({
//     url: '/system/captionLang/getLangCodeOption',
//     method: 'get',
  
//   })
// }
  // 根据langCode查询常量国际化
export function getCategoryLangByLangCode(query) {
  return request({
    url: '/system/categoryLang/getCategoryLangByLangCode',
    method: 'get',
    params: query
  })
}

    // 根据langCode查询页面国际化
export function getCaptionLangByLangCode(query) {
  return request({
    url: '/system/captionLang/getCaptionLangByLangCode',
    method: 'get',
    params: query
  })
}