import Vue from 'vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 登录、注册跳转
  {
    path: '/',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/register',
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/forget',
    component: () => import('@/views/Forget.vue'),
  },
  {
    path: '/erp',
    redirect:'/page',
    component: () => import('@/components/'),
    children:[
      {
        path: '/user',
        component: () => import('@/components/usercenter')
      },
      {
        path:'/page' ,
        component: () => import('@/components/page'),
      },
	  {
	    path:'/ERP/audit' ,
	    component: () => import('@/components/ERP/index'),
	    redirect:'/audit',
	    children:[
	      { path:'/orderList',component: () => import('@/components/ERP/order/orderList')},
	      { path:'/audit',component: () => import('@/components/ERP/order/audit')},
	      { path:'/fristSKU',component: () => import('@/components/ERP/goods/fristSKU')},
	      { path:'/houseQuery',component: () => import('@/components/ERP/goods/houseQuery')},
		  { path:'/storeInventory',component: () => import('@/components/ERP/goods/storeInventory')},
	      { path:'/storeManagement',component: () => import('@/components/ERP/store/storeManagement')},
	      { path:'/whcBinding',component: () => import('@/components/ERP/store/whcBinding')},
	      { path:'/lazada',component: () => import('@/components/ERP/store/lazada')},
	      { path:'/shopee',component: () => import('@/components/ERP/store/shopee')},
	      { path:'/warehouseing',component: () => import('@/components/ERP/warehouseing')},
	    ]
	  },
      {
        path:'/ERPP' ,
        component: () => import('@/components/ERP/index'),
        redirect:'/orderList',
        children:[
          { path:'/orderList',component: () => import('@/components/ERP/order/orderList')},
          { path:'/audit',component: () => import('@/components/ERP/order/audit')},
          { path:'/notAudit',component: () => import('@/components/ERP/order/notAudit')},
          { path:'/auditException',component: () => import('@/components/ERP/order/auditException')},
          { path:'/fristSKU',component: () => import('@/components/ERP/goods/fristSKU')},
          { path:'/houseQuery',component: () => import('@/components/ERP/goods/houseQuery')},
          { path:'/storeManagement',component: () => import('@/components/ERP/store/storeManagement')},
          { path:'/inventoryConf',component: () => import('@/components/ERP/store/inventoryConf')},
          { path:'/whcBinding',component: () => import('@/components/ERP/store/whcBinding')},
          { path:'/lazada',component: () => import('@/components/ERP/store/lazada')},
          { path:'/shopee',component: () => import('@/components/ERP/store/shopee')},
          { path:'/warehouseing',component: () => import('@/components/ERP/warehouseing')},
          { path:'/vasOrder',component: () => import('@/components/ERP/vasOrder')},
        ]
      },
      {
        path:'/WMS' ,
        component: () => import('@/components/WMS/index'),
        redirect:'/orderList',
        children:[
          { path:'/orderList',component: () => import('@/components/ERP/order/orderList')},
          { path:'/audit',component: () => import('@/components/ERP/order/audit')},
          { path:'/notAudit',component: () => import('@/components/ERP/order/notAudit')},
          { path:'/auditException',component: () => import('@/components/ERP/order/auditException')},
          { path:'/fristSKU',component: () => import('@/components/ERP/goods/fristSKU')},
          { path:'/houseQuery',component: () => import('@/components/ERP/goods/houseQuery')},
          { path:'/storeManagement',component: () => import('@/components/ERP/store/storeManagement')},
          { path:'/inventoryConf',component: () => import('@/components/ERP/store/inventoryConf')},
          { path:'/whcBinding',component: () => import('@/components/ERP/store/whcBinding')},
          { path:'/lazada',component: () => import('@/components/ERP/store/lazada')},
          { path:'/shopee',component: () => import('@/components/ERP/store/shopee')},
          { path:'/warehouseing',component: () => import('@/components/ERP/warehouseing')},
          { path:'/warehouseList',component: () => import('@/components/WMS/warehouseList')},
          { path:'/delivery',component: () => import('@/components/WMS/delivery')},
          { path:'/InOrderException',component: () => import('@/components/WMS/InOrderException')},
        ]
      },
      {
          path:'/TMS' ,
          component: () => import('@/components/TMS/index'),
          redirect:'/toBeSignedOrder',
          children:[
              { path:'/toBeSignedOrder',component: () => import('@/components/TMS/toBeSignedOrder')},
              { path:'/SimpleIn',component: () => import('@/components/TMS/SimpleIn')},
              { path:'/Inventory',component: () => import('@/components/TMS/Inventory')},
          ]
      }
    ]
  },
  // 授权回调页面
  { path:'/shopeeback',component: () => import('@/components/ERP/store/shopeeback')},
  { path:'/lazadaback',component: () => import('@/components/ERP/store/lazadaback')},
]

const router = new VueRouter({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes,


})

export default router
