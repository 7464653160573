import router from './src/router/index'
import store from './src/store/index'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'

import Cookies from 'js-cookie'//hyl-11.5
import {GetCategoryLangByLangCode,GetCaptionLangByLangCode} from "@/api/system/actions"


const whiteList = ['/','/register','/forget','/lazadaback','/shopeeback']

router.beforeEach( (to, from, next) => {

  if (getToken()) {
    // if (to.path === '/') {
    //   next()
    // } else {
      if (store.getters.roles.length === 0) {
        store.dispatch('GetInfo').then(() => {
          const langId = Cookies.get("langId") 
          if(langId==undefined||langId==null||langId==null){
            Cookies.set("langId","1")
            store.dispatch(GetCategoryLangByLangCode,"1");
            store.dispatch(GetCaptionLangByLangCode,"1");
          }else{
            store.dispatch(GetCategoryLangByLangCode,langId);
            store.dispatch(GetCaptionLangByLangCode,langId);
          }
          
          next()

        }).catch(err => { store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    // }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next({ path: '/' }) // 否则全部重定向到登
    }
  }
})

