// import { getLang,getCategoryLangByLangCode,getCaptionLangByLangCode } from "@/api/system/lang";
// import {GetCaptionLangByLangCode, GetCategoryLangByLangCode,GetLang} from "@/api/system/actions";
import { getCategoryLangByLangCode,getCaptionLangByLangCode } from "@/api/system/lang";
import {GetCaptionLangByLangCode, GetCategoryLangByLangCode} from "@/api/system/actions";
const lang = {
    state: {
        lang: [],
        langDetail: {},
        captionDetail:{},
        langId:"1",
		test: "122"
    },

    mutations: {
        SET_LANG: (state, lang) => {
            state.lang = lang
        },
        SET_LANGDETAIL: (state, langDetail) => {
            state.langDetail = langDetail
        },
        SET_LANG_ID: (state, langId) => {
            state.langId = langId
        },
        SET_CAPTIONDETAIL:(state,captionDetail)=>{
            state.captionDetail = captionDetail
        },
		TEST:(state)=>{
		    state.test = '222222'
		},
    },

    actions: {
        // 获取常量国际化
        [GetCategoryLangByLangCode]({ commit },code) {
            return new Promise((resolve, reject) => {
                getCategoryLangByLangCode({code:code}).then((res) => {      
                    commit('SET_LANGDETAIL', res.data)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取页面国际化
        [GetCaptionLangByLangCode]({ commit },code) {
            return new Promise((resolve, reject) => {
                let strid ="客户端"
                // getCaptionLangByLangCode({code:code,strid:strid}).then((res) => {     
				getCaptionLangByLangCode({code:code}).then((res) => {
                    commit('SET_CAPTIONDETAIL', res.data);
					commit('TEST', '22321312')
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    }
}
	console.log(lang.state);
export default lang