import axios from "axios";
import { Notification, MessageBox, Message } from "element-ui";
import store from "@/store";
import errorCode from "./errorCode";
import { tansParams } from "@/utils/bd";
import { getToken } from "@/utils/auth";
import Cookies from "js-cookie";
import { td } from './bd.js'

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

  // const baseURL = 'http://192.168.2.14'
  //const baseURL = "https://interface.6pl.com.cn"
  // const baseURL = 'https://cus.6pl.com.cn'
  // const baseURL = 'https://hk-api.6pl.com.cn'
  //  const baseURL = '/api'
  //  const baseURL = 'http://192.168.2.211:8091/test-api'
   const baseURL = 'https://api.6pl.com.cn' // 生产
  Cookies.set("request", baseURL);
const service = axios.create({
  baseURL: baseURL,
  timeout: 60000,
});
//请求拦截器
service.interceptors.request.use(
  (config) => {
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    return config;
  },(error) => {
    Promise.reject(error);
  }
);
//响应拦截器
service.interceptors.response.use(
  (res) => {
    //未设置状态码则默认成功状态
    const code = res.data.code || 200;
    //获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 401) {
      MessageBox.confirm(
        "登录状态已过期，您可以继续留在该页面，或者重新登录",
        "系统提示",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          store.dispatch("LogOut").then(() => {
            location.href = "/";
          });
        })
        .catch(() => {});
      return Promise.reject("令牌验证失败");
    } else if (code === 500) {
      Message({
        message: td(msg),
        type: "error",
      });
      return Promise.reject(msg);
    } else if (code !== 200) {
      Notification.error({
        title: td(msg),
      });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
	  console.log(error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export function download(url, params, filename) {
    console.log('到这里来了')
  return service.post(url, params, {
      transformRequest: [(params) => {
          return tansParams(params)
      }],
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Authorization": "Bearer  " + getToken()
      },
      responseType: 'blob'
  }).then((data) => {
      const content = data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, filename);
      }
    })
    .catch((r) => {
      console.error(r);
    });
}


export function download2(url, params, filename) {
  console.log(params,'params');
  return service
    .post(url, params, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      responseType: "blob",
    })
    .then((data) => {
      const content = data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, filename);
      }
    })
    .catch((r) => {
      console.error(r);
    });
}


export default service;
